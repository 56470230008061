import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import style from './style.module.less';
import { Header } from '../../components/header';
import { Footer } from '../../components/footer';
import { Wrapper } from '../../components/wrapper';
import * as Sentry from '@sentry/react';
import { useAppSelector } from '../../redux/hooks';
import selectors from '../../redux/selectors';
import { getAiLawyerImage, getRandomAiLawyer } from '../../utils/ai-lawyer';
import { ReportCard } from '../../components/report-card';
import { clarity } from 'react-microsoft-clarity';

export const MaintenanceComponent: React.FC = () => {
  const { t } = useTranslation();
  const [lawyerPic, setLawyerPic] = useState<string | null>(null);

  const fetchSessionError = useAppSelector(selectors.session.fetchError());

  useEffect(() => {
    const randomPic = getAiLawyerImage({
      aiLawyer: getRandomAiLawyer(),
      angled: true,
    });
    setLawyerPic(randomPic);
  }, []);

  useEffect(() => {
    Sentry.captureEvent({
      message:
        'Maintenance page loaded - ' +
        window.location.href +
        ' - ' +
        fetchSessionError,
      level: 'error',
    });
    clarity.setTag('Maintenance', fetchSessionError);
  }, []);


  return (
    <>
      <Header className={style.header} />
      <Wrapper className={style.wrapper}>
        <ReportCard
          id={'maintenance'}
          title={t('pages.maintenance')}
          image={<img src={lawyerPic} alt='AI Lawyer' />}
          className={style.maintenanceCard}
          titleClassName={style.title}
        >
          <div className={style.textContainer}>
            <div className={style.bodyText}>
              <div
                dangerouslySetInnerHTML={{
                  __html: t('pages.maintenance-body'),
                }}
              />
            </div>
          </div>
        </ReportCard>
        
      </Wrapper>
      <Footer />
    </>
  );
};
